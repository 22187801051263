 
import './App.css';

function App() {
  return (
    
    <div className="app">
     <h1>WELCOME</h1>
    
    </div>
 
  );
}

export default App;
